.attorney-resource {
    background-color: #fff;
    background-image: url("../../assets/images/Subtract.png");
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 150px;
}

.card {
    padding: 0px 0px 45px 5px;
}

.call-btn {
    margin-top: 0;
    padding-bottom: 20px
}

.success {
    background-image: url("../../assets/images/Rectangle\ 125.png");
    background-size: cover;
    width: 100%;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.success h2 {
    color: white;
    font-size: 2em;
    padding-top: 26px;
}

.card-one,
.card-two,
.card-three {
    border: 1px solid #ffffff;
    background: rgba(255, 255, 255, 0.60);
    padding: 20px;
    text-align: center;
    margin-bottom: 22px;
}

.card-one p,
.card-two p,
.card-three p {
    color: #403636;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin: 10px 0;
}

.schedule-call-button1 {
    background: #C3AA91;
    padding: 11px 48px;
    text-decoration: none;
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin: 0;
}

.attorney-text-overlay h1 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    .attorney-text-overlay h1 {
        padding: 27px;
    }
    .col-md-4.col-sm-12 {
        margin-bottom: 10px !important;
    }
}

.attorney-text-overlay p {
    line-height: normal;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    padding-bottom: 18px;
    font-weight: 400;
    line-height: 1.9;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 17px;
}

a.schedule-call-button1 {
    color: white;
}

.card p {
    line-height: normal;
    color: #403636;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    padding-top: 45px;
    padding-bottom: 18px;
}

.informed h3 {
    padding-top: 50px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.access-contents {
    text-align: center;
    display: flex;
}

.informed p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 15px;
    line-height: 1.9;
}

.access-resources h3 {
    padding-top: 22px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.access-contents p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    padding: 5px;
}

.expert h3 {
    padding-top: 26px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.expert p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    padding-top: 0px;
    padding-bottom: 60px;
    font-size: 15px;
}

.card {
    background: rgba(255, 255, 255, 0.60);
    margin-top: 12px;
}

.death p {
    font-size: 18px;
    padding-left: 20px;
    text-align: left;
}

.center h1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.raising h1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
}

.raising h1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.virtual p {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.lunch h1 {
    padding-top: 20px;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    padding-bottom: 20px;
}

.lunch h1 span {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.lunch p {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

@media screen and (max-width: 768px) {

    .virtual p,
    .lunch p,
    .lunch h1 {
        padding: 0 30px;
    }
}

.learning {
    padding-top: 35px;
    padding-bottom: 35px;
}

/* .dueling-section {
    padding-left: 200px;
} */

@media screen and (max-width: 768px) {
    .dueling-section {
        padding-left: 0px;
    }
}

.real-property {
    background: #EAEAEA;
    padding: 20px;
}

.dueling h1 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 10px 0;
}

.image-gallery {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.duel-img {
    flex: 1;
    max-width: 100%;
    text-align: center;
    margin: 10px;
}

/* .duel-img img {
    width: 100%; */
/* max-width: 100%;
} */

.duel-img h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    /* padding-left: 38px; */
}

.duel-img p {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    text-align: left;
    /* padding-left: 38px; */
}

@media screen and (max-width: 767px) {
    .real-property {
        padding: 20px;
    }

    .dueling h1 {
        font-size: 22px;
    }

    .duel-img {
        flex: 1;
        max-width: 100%;
    }

    .duel-img h3 {
        font-size: 16px;
        text-align: center;
    }

    .duel-img p {
        font-size: 12px;
        text-align: center;
    }

    .attorney-resource {
        padding-top: 86px;
    }
}

/* affidavit */
/* .affidavit {
    padding-left: 100px;
    padding-top: 71px;
} */

.border-list {
    list-style: none;
    padding: 0;
}

.border-item {
    border-top: 1px solid #C4AC93;
    border-bottom: 1px solid #C4AC93;
    padding: 10px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    margin-top: 10px !important;
}

.icns {
    display: flex;
}

.faFile {
    font-size: 30px;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .affidavit {
        padding-left: 0;
    }

    .border-item {
        flex-direction: column;
        text-align: center;
    }

}


.register-bttn{
    color: white;
    background: #897764;
    border: 1px;
    font-size: 15px;
    padding: 6px 10px 6px 10px;
    transition: background 0.3s, color 0.3s, border 0.3s;
}

.register-bttn:hover {
    background: #fff;
    color: #000;
    border: 1px solid #000;
}