.Laurel-starrkks{
    font-size: 30px;
  
    font-weight: bold;
}
.appointtedstarkss{
    font-size: 28px;
    text-align: center;
 
}
.sttarttbtnnn{
    text-align: center;
   
}
.butttoonnclickk{
    background-color: #C3AA91 !important;
    color: white !important;
    border: 1px solid white !important;
    padding: 8px 50px 8px 50px !important;
    font-size: 20px !important;
    font-weight: bolder !important;
}
.butttoonnclickk:hover{
    color: black !important;
}
.yesssbtnnn{
    font-size: 23px !important;
    padding: 5px 40px 5px 40px !important; 
}
.yesssbtnnn:hover{
    background-color: #C3AA91 !important;
    color: white !important;
    border: 1px solid white !important;
}
.butttooonnookk{
    background-color: #C3AA91 !important;
    color: white !important;
    font-size: 23px !important;
    font-weight: 600 !important;
    border: 1px solid white !important;
}
.butttooonnookk:hover{
    background-color: black !important;
    color: white !important;
}
.residentialbttn{
    font-size: 18px !important;
    font-weight: 600 !important;
    padding: 12px 50px 12px 50px !important;
}
.residentialbttn:hover{
    background-color: #C3AA91 !important;
    color: white !important;
    border: 1px solid white !important;
}
.deaaddoftrustbtn{
    width: 100%;
    font-size: 20px !important;
    font-weight: 500 !important;
    padding: 10px 60px 10px 60px !important;
}
.deaaddoftrustbtn:hover{
    background-color: #C3AA91 !important;
    color: white !important;
    border: 1px solid white !important;
    font-weight: bolder !important;
}
.btnnnsuubbmmitt{
    background-color: #C3AA91 !important;
    color: white !important;
    font-size: 19px !important;
    font-weight: 600 !important;
    padding: 12px 45px 12px 45px !important;
}
.btnnnsuubbmmitt:hover{
    background-color: black !important;
    color: white !important;
    font-weight: bolder !important;
    border: 1px solid white !important;
}
@media (max-width: 768px) {
    .deaaddoftrustbtn{
        width: 100% !important;
        padding: 9px 36px 9px 30px !important;
    }
}


@media (max-width: 767px) {
    .fontdesignings,
    .step-arrow {
      display: none;
    }
  }