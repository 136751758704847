.resource {
    background-color: #fff;
    /* Change the background color to white */
    background-image: url("../../assets/images/Subtract.png");
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 150px;
}

.loaannnammouunts {
    width: 30% !important;
}

.longtermmm {
    margin-left: 20px !important;
}

.sliderrboxxx {
    margin-top: 22px !important;
    margin-bottom: 22px !important;
}

.card {
    padding: 0px 0px 45px 5px;
}

.call-btn {
    margin-top: 0;
    padding-bottom: 20px
}

.success {
    background-image: url("../../assets/images/Rectangle\ 125.png");
    background-size: cover;
    width: 100%;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.success h2 {
    color: white;
    font-size: 2em;
    padding-top: 26px;
}

.card-one,
.card-two,
.card-three {
    border: 1px solid #ffffff;
    background: rgba(255, 255, 255, 0.60);
    padding: 20px;
    text-align: center;
    margin-bottom: 22px;
}

.card-one p,
.card-two p,
.card-three p {
    color: #403636;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    margin: 10px 0;
}

.schedule-call-button1 {
    background: #C3AA91;
    padding: 11px 48px;
    text-decoration: none;
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin: 0;
    /* Remove margin */
}

.text-overlay h1 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.text-overlay p {
    line-height: normal;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;

    /* 150% */
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;

    padding-bottom: 18px;

}

a.schedule-call-button1 {
    color: white;
}

.card p {
    line-height: normal;
    color: #403636;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 150% */
    padding-top: 45px;
    padding-bottom: 18px;
}


.informed h3 {
    padding-top: 50px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.access-content {
    text-align: center;
}

.informed p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
    padding-top: 0px;
    padding-bottom: 0px;
}


.access-content h3 {
    padding-top: 22px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.access-content p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
    padding: 5px;
}

.expert h3 {
    padding-top: 26px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.expert p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
    padding-top: 0px;
    padding-bottom: 60px;
}

.card {
    background: rgba(255, 255, 255, 0.60);
}

/* divorce */

.divorce h3 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding: 25px;
}

.divorce {
    padding-top: 70px;
    padding-bottom: 12px;
    text-align: center;
}

.image-section {
    text-align: center;
    /* Center the content */
}

.image-section-img {
    display: flex;
    justify-content: center;
}

.image-section p {
    padding-left: 14px;
    color: #000;
    font-family: Montserrat;
    /* float: left; */
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    text-align: center !important;
    margin-top: 10px !important;
}

/* Financial  */
.financial {
    padding-top: 60px;
    padding-bottom: 60px;
}

.financial h3 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.financial img {
    width: 100%;
}


/* bar */
.bar {
    max-width: 100%;
    height: 10px;
    background-color: black;
    margin-right: 5px;
}

.bar {
    display: inline-block;
    max-width: 100%;
    /* Adjust the width of the bar as needed */
    height: 10px;
    /* Adjust the height of the bar as needed */
    background-color: black;
    /* Adjust the color of the bar as needed */
    margin-right: 5px;
    /* Adjust the spacing between the bar and text as needed */
}

.loan-amount p,
.paymnets-amounts p {
    font-size: 16px;
    padding-left: 20px;
    padding-top: 20px;
    text-align: left;
}

.box-shadow-cls {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    padding-bottom: 40px;
    /* Center the box on small screens */
}

.box-shadow-cls1 {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    width: 100%;
    padding-bottom: 42px;
    font-size: 15px;
}

.detail {
    display: flex;
    align-items: center;
    background: #bea78f;
    padding: 20px;
}

.home-icn {
    margin-right: 10px;
    font-size: 25px;
}

.loan-amount-monthly p {
    font-size: 18px;
    padding-left: 20px;
    padding-top: 20px;
    text-align: left;
}

.interest-saving {
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .container {
        padding: 10px;
        /* Reduce container padding on small screens */
    }

    .col-md-6 {
        width: 100%;
        /* Make both columns take full width on small screens */
    }

    .box-shadow-cls {
        width: 100%;
        /* Adjust the width for small screens */
    }

    .box-shadow-cls1 {
        width: 100%;
        margin-top: 20px;
        /* Adjust the width for small screens */
    }

    .loan-amount p,
    .loan-amount-monthly p,
    .paymnets-amounts p,
    .interest-saving {
        font-size: 14px;
        /* Reduce font size on small screens */
    }

    .detail {
        flex-direction: column;
        /* Stack elements vertically on small screens */
        text-align: center;
    }
}

.paymnets-amounts {
    font-size: 20px;
}


/* vvv */
@media (max-width: 768px) {
    .estimated-detail {
        flex-direction: column;
        /* Stack elements vertically on small screens */
        text-align: center;
    }
}

.estimated-detail {
    font-size: 16px;
    align-items: center;
    background: #bea78f;
    padding: 10px 20px;
    text-align: left;
}

.stimated-detail p {
    font-size: 20px;
}


.tax-paid {
    text-align: left;
    font-size: 16px;
    padding-left: 33px;
    padding-top: 35px;
}

.price {
    font-size: 18px;
    padding-top: 35px;
    padding-left: 56px;
    text-align: left;
}

.pie-chart {
    padding-left: 30px;
}

@media (max-width: 767px) {
    .tax-paid {
        padding-left: 10px;
        padding-top: 20px;
    }

    .price {
        font-size: 16px;
        padding-top: 20px;
        padding-left: 10px;
    }

    .loan-breakdown h5 {
        font-size: 20px;
        padding: 20px 2px;
    }

    /* Center-align text within .tax-paid and .price */
    .tax-paid,
    .price {
        text-align: center;
    }

    /* Adjust the height of the box to accommodate the content */
    .box-shadow-cls1 {
        height: auto;
    }
}

.loan-breakdown h5 {
    font-size: 23px;
    padding: 38px 2px 8px 2px;
}

/* Add responsive styles for mobile screens */
@media (max-width: 767px) {
    .tax-paid {
        padding-left: 10px;
        padding-top: 20px;
    }

    .price {
        font-size: 16px;
        padding-top: 20px;
        padding-left: 10px;
    }

    .loan-breakdown h5 {
        font-size: 20px;
        padding: 20px 2px;
    }

    /* Center-align text within .tax-paid and .price */
    .tax-paid,
    .price {
        text-align: center;
    }

    /* Adjust other styles as needed for a better mobile layout */
}

/* Additional media queries for different screen sizes can be added if needed */


/* */

/* YourComponent.css */

.percentage-input {
    border-right: none;
    /* Remove the right border for the input field */
    border-top-right-radius: 0;
    /* Adjust the border radius to match the span */
    border-bottom-right-radius: 0;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 8px 9px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-tertiary-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}

.inputs-froms p {
    font-size: 16px;
    text-align: left;
    padding-left: 37px;
    margin-top: 9px !important;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    /* float: right; */
    /* padding-left: 75px;
    padding-right: 30px; */
    /* text-align: left; */
    /* align-items: flex-start; */
}

.inputs-form-payments {
    padding-top: 35px;
}

button.btn.btn-primary {

    background-color: #fff;
    color: black;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid grey;
    padding: 8px;
}

.MuiBox-root.css-1domaf0 {
    width: 100%;
    padding-left: 48px;
    padding-right: 50px;
}

.image-section-img {
    padding-left: 150px;
}

.pmi-text-12 p {
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
}

.loaanammount {
    margin-left: 11px !important;
}

@media (max-width: 767px) {
    .image-section-img {
        padding-left: 0px;
    }



}

.input-fileds {
    padding-left: 250px;
}

@media (max-width: 767px) {
    .input-fileds {
        padding-left: 0px;
    }
}




.homevalue-slider {
    max-width: 80%;
    margin-left: 50px !important;
}
.amortization{
    margin-top: 20px !important;
}