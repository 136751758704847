a.nav-menu-list {
    font-size: 15px;
}
.navbar-expand-lg {
    justify-content: center !important;
}

a.nav-item.nav-link.active.spaced-link {
    font-size: 10px;
}

.nav-item-select {
    background: #77634d !important;
    border-radius: 10px;
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        justify-content: center;
    }

    a.nav-item.nav-link.active.spaced-link {
        font-size: 14px;
    }
}

nav.navbar.navbar-expand-lg.fixed-top.navbar.navbar-expand-lg.navbar-light {
    padding-top: 10px;
    padding-bottom: 16px;
}

a.nav-item.nav-link.active.spaced-link {
    letter-spacing: 0;
    font-size: 17px;
    text-shadow: 1px 1px #000;
    font-weight: 400;
}

nav.navbar.navbar-expand-lg.fixed-top.navbar.navbar-expand-lg.navbar-light {
    background: linear-gradient(180deg, rgba(178, 149, 121, 0.00) 0%, #593F26 105.86%);
}

.modal-btn {
    float: left !important;
    margin-top: 10px !important;
}

.header-form-inputs {
    margin-top: 20px !important;
    border-bottom: 1px solid grey !important;
    border: unset !important;
    box-shadow: none !important;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

input.header-form-inputs.form-control {
    border-bottom: 1px solid grey !important;
    border-radius: unset !important;
    font-size: 16px;
    width: 70%;
    padding: 26px 0px 26px 27px;
}

.from-label {
    font-size: 20px;
}

.modail-container {
    position: absolute !important;
    left: 20% !important;
    top: 10% !important;
    padding: 20px;
}

.modal-body {
    padding: 30px !important;
}

.modal-footer {
    border-top: unset !important;
}

.footer-icon {
    width: 6%;
    padding: 10px;
    margin: 5px !important;
    background: #B79D82 !important;
    border: unset !important;
    color: #fff !important;
    border-radius: unset !important;
}

.modal-ok-btn {
    padding: 6px 46px !important;
    margin-top: 20px !important;
    border-radius: unset !important;
}

@media screen and (max-device-width: 767px) {
    .navbar-p {
        padding-left: 0px !important;
    }

    span.navbar-brand {
        margin-left: 150px !important;
        margin-bottom: 14px !important;
    }
   
}

@media screen and (max-device-width: 768px) {

    span.navbar-brand {
        margin-left: 0px !important;
        margin-bottom: 14px !important;
    }
    .modail-container {
        position: absolute !important;
        left: 0% !important;
        top: 0% !important;
    }

    .modal-content {
        height: 45vh !important;
    }

    .footer-icon {
        width: 12% !important;
    }
}


.modals-btn {
    display: grid;
    width: 12%;
}

.PhoneInput {
    display: flex;
    align-items: center;
    width: 31%;
    padding: 12px 8px 5px 6px;
}

.document-options-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 50%;
}

.document-option-btn {
    text-align: left;
    width: 100%;
}

.get-started {
    float: left !important;
}

.oder-modal-content {
    font-size: 16px;
}

label.form-label {
    padding: 26px 0px 0px 0px;
    font-size: 14px;
}


a.nav-item.nav-link.active.spaced-link.nav-item-select {
    height: 38px;
}

div#basic-navbar-nav {
    margin-top: 12px !important;
}

