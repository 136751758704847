.stipulation {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    /* Add left padding for space */
    padding-right: 10px;
    /* Add right padding for space */
}

.stipulation-img img {
    width: 100%;
    /* height: 618.101px; */
    flex-shrink: 0;
}

.estate {
    text-align: left;
}

.estate h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3.9px;
    text-transform: uppercase;
}

.starks p {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 23px; */
}

.witness p {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 143.75% */
}

.uniqueness p {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 23px; */
    /* 143.75% */
}

.author p {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    /* 143.75% */
}

.accesss {
    display: flex;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

.acces-one {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}


.access-btn Button {
    background: #B79D82;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    border: 0px;
    display: block;
    /* To center the button */
    margin: 0 auto;
}

.access-stipulation-btn Button {
    background: #fff;
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    border: 1px solid;
    display: block;
    /* To center the button */
    margin: 0 auto;
}

/* Add a media query for responsiveness */
@media (max-width: 768px) {
    .access-btn Button {
        padding: 11px 8px 12px 8px;
    }

    .stipulation {
        padding-left: 20px;
        /* Increase left padding for more space on mobile */
        padding-right: 20px;
        /* Increase right padding for more space on mobile */
    }

    .stipulation-img img {
        width: 100%;
        /* Adjust image width for responsiveness */
        height: auto;
        /* Allow the image to scale proportionally */
    }

    .access {
        display: flex;
        justify-content: space-between;
        /* Display buttons in a row */
        flex-direction: column;
        /* Stack buttons vertically on small screens */
        align-items: center;
        /* Center-align buttons horizontally */
    }

    .access-button {
        margin: 5px;
        /* Adjust the margin as needed for the desired gap */
    }

    .access-btn,
    .access-stipulation-btn {
        margin: 10px 0;
        /* Add spacing between buttons */
    }
}

/* General button styling */
.access-button {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    border: 0;
    display: block;
    margin: 0 auto;
    padding: 12px 50px 13px 50px;
}

/* Style for the "Access CV" button */
.access-btn button {
    background: #B79D82;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

/* Style for the "Access Stipulation" button */
.access-stipulation-btn button {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.access-stipulation-btn {
    margin-left: 20px !important;
    /* margin-top: 20px !important; */
}

.access-stipulation-btn button:hover {
    background: #a39584;
    color: white;
    border: 1px solid #fff;
}

@media (max-width: 767px) {
    .access-stipulation-btn {
        margin-left: 20px !important;
        margin-top: 27px !important;
    }

    .access-button {
    
        padding: 12px 5px 12px 5px;
    }

    .estate h1 {
        margin-bottom: 14px !important;
        font-size: 25px;
    }

    .stipulation {
        padding: 26px 20px;
    }

    .laurel-starks {
        padding-left: 0px;
    }

    .lunch h1 {
        padding: 20px !important
    }

    .duel-img p {
        padding-left: 0px !important;
    }
}

