.advance-program {
    background-color: #fff;
    background-image: url("../../assets/images/Subtract.png");
    width: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 120px;
    padding-bottom: 120px;
}

.centered-content h1 {
    color: #FFF !important;
    text-align: center !important;
    font-family: Montserrat;
    font-size: 40px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal;
    letter-spacing: 2px !important;
    text-transform: uppercase;
}

.centered-content p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.centered-content {
    text-align: center;
}

.feature-container {
    text-align: center;
    margin-top: 20px;
}

.text-overlay1 p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
}

.schedule-call-button {
    background-color: #fff;
    padding: 16px 20px;
    text-decoration: none;
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    border: 0px;
}

.call-btn {
    margin-top: 45px;

}

.schedule-call-button:hover {
    background: #B79D82;
    text-decoration: none;
    color: black;
}

.feature-container h2 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.feature-container p {
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

h2.cost-advance {
    margin-top: 67px;
}

.slider-img img {
    width: 100%;
    height: 400px;
    flex-shrink: 0;
}

.studies {
    padding-top: 69px;
    padding-bottom: 100px;
    padding-left: 150px;
    padding-right: 150px;
    margin-left: 10%;
}

/* .carousel-items {
    padding-top: 45px;
} */

.studies h2 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.studies h3 {
    color: #232323;
    text-align: center;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    text-transform: uppercase;
}

li {
    text-align: left;
}

.improve {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 20px;
    text-align: left;
}

.improve h2 {
    text-align: left !important;
    color: #232323;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    text-transform: uppercase;
}

.improve ul li {
    color: #232323;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}


@media (max-width: 768px) {
    .improve ul li {
        line-height: 2;
    }
    
}

.improve p {
    color: #232323;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
}

.improve p span {
    color: #232323;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

span.carousel-control-prev-icon {
    display: none;
}

.transformation {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px solid #000;
    padding: 5px 15px;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
}

.improve {
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 20px;
    text-align: left;
}

.improve h2 {
    text-align: left;
}

span.carousel-control-next-icon {
    display: none;
}

/* Updated CSS for mobile responsiveness */
@media (max-width: 768px) {
    .studies {
        padding: 20px;
        margin-left: 5%;
    }

    /* .carousel-items {
        padding-top: 20px;
        text-align: center;
    } */

    .slider-img img {
        width: 100%;
        height: auto;
    }

    .improve {
        font-size: 16px;
        line-height: 24px;
        padding: 10px;
        text-align: center;
    }

    .improve h2 {
        text-align: center !important;
        font-size: 20px;
    }

    .improve ul li {
        font-size: 16px;
        line-height: 2;
        list-style: none;
        text-align: center;
    }

    .result-info p,
    .result-info span {
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }

    .transformation {
        text-align: center;
    }
}

/* success */
.success {
    background-image: url("../../assets/images/Rectangle\ 125.png");
    background-size: cover;
    width: 100%;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.success h2 {
    color: white;
    font-size: 2em;
    padding-top: 26px;
}

.cardss {
    padding-top: 39px;
    padding-bottom: 51px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.column {
    flex: 1;
    padding: 10px;
}

.average {
    color: white !important;
}

.card-one,
.card-two,
.card-three {
    border: 1px solid #ffffff !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    padding: 20px !important;
    text-align: center !important;
    margin-bottom: 22px !important;
}

.card-one h1,
.card-two h1,
.card-three h1 {
    color: #FFF !important;
    font-size: 60px !important;
    font-family: Montserrat;
    font-weight: 600 !important;
    line-height: 1.2 !important;
}

.card-one p,
.card-two p,
.card-three p {
    color: #FFF !important;
    font-size: 1.5em !important;
    font-family: Montserrat;
    font-weight: 600 !important;
    /* line-height: 1.5 !important; */
}

/* Add media queries for smaller screens */
@media screen and (max-width: 768px) {
    .success h2 {
        font-size: 1.5em;
    }

    .col-3 {
        flex: 1;
        padding: 10px;
        width: 100%;
    }

    .col-3 h1 {
        margin: 10px;
    }

    .col-3 p {
        font-size: 1em;
    }
}


/*service */
.services {
    padding-top: 30px;
    padding-bottom: 30px;
}

.covered-services {
    padding-top: 40px;
}

.covered-services {
    padding-top: 40px;
    text-align: center;
}

.covered-services h3 {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.column-container {
    display: flex;
    justify-content: center;
}

.column {
    flex: 0 0 30%;
    margin: 0 10px;
}

.service-list ul li {
    color: #232323;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}


.more-sale {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

@media screen and (max-width: 768px) {
    .column {
        flex: 0 0 100%;
        margin: 0;
    }

    .service-list ul li {
        font-size: 14px;
    }

    .more-sale {
        font-size: 16px;
    }
}

/* works*/

.works {
    padding-top: 50px;
}

.work-img img {
    width: 100%;
}

.work-steps {
    text-align: left;
}

.work-steps h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
    text-transform: uppercase;
}

.work-steps h5 {
    color: #000;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}

.work-steps p {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}

.step-six {
    color: #000;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}

/* collapse */
.collapse-list {
    padding-top: 50px;
    padding-bottom: 50px;
}

.question-container {
    color: #000;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    padding: 7px;
}

.question-container p {
    border-top: 1px solid #C4AC93;
    border-bottom: 1px solid #C4AC93;
    background: #FFF;
    color: #000;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 14px;
}

svg.svg-inline--fa.fa-plus.icon {
    float: right;
}

svg.svg-inline--fa.fa-minus.icon {
    float: right;
}


@media (max-width: 767px) {
    p.mt-5 {
        padding: 11px 19px;
    }

    .feature-container p {
        padding: 2px 21px;
    }

    .collapse-list {
        padding-top: 35px;
        padding-bottom: 35px;
    }
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
    background-color: #000;
    display: none;
}


@media (max-width: 767px) {
    p.mt-5 {
        padding: 11px 19px;
    }

    .feature-container p {
        padding: 2px 21px;
    }

    .advance-program-section {
        padding-left: 0px;
    }

    .work-steps p {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
    }
}

.question-section {
    padding-left: 100px;
    padding-right: 100px;
}

@media (max-width: 767px) {
    .question-section {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.cleanup {
    /* padding-left: 400px; */
    float: inline-end;
    margin-top: 20px !important;
}

@media (max-width: 767px) {
    .cleanup {
        padding-left: 0px;
        float: left;
        margin-top: 5px !important;
        padding-left: 20px;
    }
   
}


.preparing {
    text-align: center;
    padding: 0 80px;
}

.preparing p {
    display: inline-block;
    line-height: 1.6;
    text-transform: none;
}

.services h3{
    color: #000;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}


@media (max-width: 768px) {
  
    .preparing {
        text-align: center;
        padding: 0 20px;
    }
    
}
