.speakinglook{
    text-align: center;
    font-size: 25px;
}
.ggeetstarted{
    text-align: center;
}
.startsss{
    font-size: 20px !important;
    padding: 5px 20px 5px 20px;
    background-color: #C3AA91;
    color: white;
    border: 2px solid white;
}
.startsss:hover{
    color: black;
    font-weight: bold;
}
input.form-control.is-invalid {
    width: 50%;
    font-size: 17px;
    padding: 25px 0px 25px 15px;
}

.nameokbtnnn{
    border: 1px solid white;
    background-color:#C3AA91 ;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 7px 50px 7px 50px !important;
}
.nameokbtnnn:hover{
    background-color: black;
    color: white;
   
}
.PhoneInputCountryIcon.PhoneInputCountryIcon--border {
    font-size: 25px;
    padding: 0px 6px 0px 0px;
}

.header-form-inputs{
margin-left: 20px !important;

}
input.PhoneInputInput {
    padding: 6px 0px 6px 26px;
}
.btnnhandlesubmitt{
    border: none;
    font-size: 19px;
    font-weight: bold;
    background-color:#C3AA91 ;
    color: white;
}
.btnnhandlesubmitt:hover{
    background-color: #000;
    color: white    ;
}
.yourrnameee{
    width: 50% !important;
    font-size: 17px !important;
    padding: 25px 0px 25px 15px !important;
}
.namevaliddationn{
    color: #000 !important;
    font-size: 14px !important;
}
.fontdesignings{
    font-size: 30px;
    font-weight: bold;
}
.arrowdesignss{
    font-size: 19px;
    margin-bottom: 13px !important;
}
.labellinputt {
    margin-left: 12px !important;
}
.modal-height{
line-height: 2;
}

.PhoneInput {
    align-items: center;
    width: 50% !important; 
    padding: 12px 8px 5px 6px;
}
.phone-input {
    margin-right: 10px !important; 
  }
  
.PhoneInput {
    display: flex;
    align-items: center;
    width: 50% !important; 
    padding: 12px 8px 5px 6px;
}

.PhoneInputCountryIcon {
    margin-left: 10px; 
}
.PhoneInputCountry {
    margin-left: 5px; 
}

@media (max-width: 768px) {
    .yourrnameee{
        width: 100% !important;
    }
    .header-form-inputs.PhoneInput{
        width: 100% !important;
    }
    label.form-label.form-label {
      font-size: 11px !important;
    }
    .fontdesignings{
        font-size: 18px !important;
    }
    .arrowdesignss{
        font-size: 12px;
        margin-bottom: 7px !important;
    }
   
    }

.text-end button{ 
    margin: 10px !important;
    color: #fff;
    background: #b79d82;
    border: 1px;
}