/* Add space between links and set text color to white */

* {
  margin: 0 !important;
}

.schedule-btns {
  border: 1px solid #fff !important;
  background: #fff !important;
  padding: 12px 28px 10px 20px !important;
}

.video-margin {
  margin-top: 50px !important;
}

.schedule-btns:hover {
  border: 1px solid #fff !important;
  background: #B79D82 !important;

}

.navbar-nav .nav-link {
  color: white;
}

.navbar-toggler-icon {
  background-color: white;
}

/* common css */
.regular-font-heading {
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  /* 124% */
  text-transform: uppercase;
}

.client-success-stories {
  font-size: 35px;
}

.regular-font {
  color: #fff;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 31px;
}

.carousel-items {
  font-size: 30px;
  line-height: inherit;
  padding-top: 57px;
}

.p-20 {
  padding: 20px;
}

.mb-60 {
  margin-bottom: 56px;
}

.color {
  color: #000 !important;
}


/* group sections css */
.group-section {
  padding-top: 100px;
  padding-bottom: 100px;
  /* height: 420.855px; */
  background-image: url("../src/assets/images/Rectangle\ 136.png");
  padding-top: 7.5%;
  background-size: cover;
}

.carousel-text-box {
  position: relative;
  top: 10%;
}

/* .carousell-img {
  width: 456px;
  height: 282px;
  object-fit: cover;
} */

.carousell-img {
  width: 90%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .carousell-img {
    width: 250px;
    height: 282px;
    object-fit: cover;
  }
  .carousel-items {
    font-size: 25px;
    line-height: inherit;
    text-align: center;
    padding-top: 0px;
  }
  
}

.carousel-indicators .active {
  background-color: #c7b29a;
  border: 1px solid #c7b29a;
}

.carousel-indicators button {
  width: 28px !important;
  height: 4px !important;
  margin-left: 6px !important;
  border: unset;
}

.carousel-control-prev span.visually-hidden {
  display: none !important;
}

.carousel-control-next span.visually-hidden {
  display: none !important;
}

.carousel-indicators {
  bottom: 28px;
}

.carsel-row-box {
  padding: 50px;
}


ye replace kar do .video-text {
  color: #232323;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  margin-left: 14px !important;
  margin-top: 6px;
}


/* team section css */

.team-section-conatiner {
  background: #fffbf6;
  padding: 50px 150px;
}

.team-text-heading {
  color: #000;
  font-family: Montserrat;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.9px;
  text-transform: uppercase;
}

.team-text-sub-heading {
  color: #000;
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 110px;
}




.home-img {
  height: 515px;
  width: 100%;
  margin-top: 8px !important;
}

.signature-text {
  color: #000;
  font-family: Moon Dance;
  font-size: 30px;
  font-style: italic;
  font-weight: 400;
  line-height: 26px;
}

.team-section-container {
  padding: 50px;
  /* Adjust the padding for larger screens */
}

/* Customize styles for different screen sizes */
@media (max-width: 768px) {
  .team-section-container {
    padding-top: 35px;
    padding-bottom:35px ;
    text-align: center;
    /* Adjust the padding for larger screens */
  }

  .team-text-sub-heading {
    padding-left: 0px;
    text-align: center;
  }
}

/* Center the team-text-heading */
.team-text-heading {
  text-align: left;
  padding-left: 110px;
}

@media (max-width: 767px) {
  .team-text-heading {
    text-align: center;
    padding-left: 0px;
  }

  .video-margin {
    margin-top: 12px !important;
  }

  .video-text {
    font-size: 20px !important;
    margin-top: 7px !important;
    margin-left: 10px !important;
  }

  .regular-font p {
    text-align: center !important;
  }
}

/* Center the signature text */
.signature-text {
  text-align: left;
}


.team-btn {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #fff;
  border: 1px solid #000;
}


.success-container {
  background-image: url("../src/assets/images/Rectangle\ 129.png");
  height: 445px;
  background-size: cover;
}

.success-blog-box {
  height: 300px;
}

.success-light-theme:nth-child(odd) {
  opacity: 0.8;
  background: linear-gradient(180deg, #FFF 0%, rgba(178, 149, 121, 0.00) 64.86%);
  height: 300px;
}

.success-light-theme:nth-child(even) {
  opacity: 0.8;
  background: linear-gradient(180deg, rgba(178, 149, 121, 0.00) 0%, #593F26 105.86%);
  height: 300px;
}

.success-text {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.success-light-theme:nth-child(odd) .success-text {
  position: relative;
  top: 18%;
  font-weight: 600;
}

.success-light-theme:nth-child(even) .success-text {
  position: relative;
  top: 30%;
  color: #fff;
  font-weight: 400;
}

.success-heading {
  color: #FFF;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  top: 40%;
}

.success-row-box {
  padding: 50px;
}

.multisection-sub-boxes {
  padding: 50px 30px;

}

/* multisections css */
.multisection-sub-boxes:nth-child(1) {
  background-image: url("../src/assets/images/Rectangle\ 132.png");
  text-align: right;
  color: #fff;
  background-size: cover;
}

.multisection-sub-boxes:nth-child(2) .multisection-heading,
.multisection-text {
  background-image: unset;
  color: #232323;
}

.multisection-sub-boxes:nth-child(3) {
  background-image: url("../src/assets/images/Rectangle\ 132.png");
  color: #FFF;
  background-size: cover;
}

.multisection-sub-boxes:nth-child(4) .multisection-heading,
.multisection-text {
  background-image: unset;
  color: #232323;
}

.multisection-sub-boxes:nth-child(5) .multisection-heading,
.multisection-text {
  background-image: unset;
  color: #232323;
}

.multisection-sub-boxes:nth-child(5) .multisection-text {
  color: #000000 !important;
}

.multisection-sub-boxes:nth-child(6) {
  background-image: url("../src/assets/images/Rectangle\ 132.png");
  color: #FFF;
  background-size: cover;
}

.multisection-sub-boxes:nth-child(6) .multisection-text {
  color: #fff !important;
}

.multisection-sub-boxes:nth-child(7) .multisection-heading,
.multisection-text {
  background-image: unset;
  color: #232323;
}

.multisection-sub-boxes:nth-child(8) {
  background-image: url("../src/assets/images/Rectangle\ 132.png");
  text-align: left;
  color: #FFF;
  background-size: cover;
}

.multisection-sub-boxes:nth-child(7) .multisection-text {
  color: #000000 !important;
}

.multisection-text:nth-child(8) {
  color: #FFF !important;
}

.multisection-sub-boxes:nth-child(8) .multisection-text {
  color: #fff !important;
  text-align: center;
}

.multisection-heading {
  text-align: center;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  text-transform: uppercase;
}

.multisection-text {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.multisection-about-text {
  margin-top: 33px !important;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .multisection-about-text {
    font-size: 24px;
    margin: 8px !important;
  }

  .multisection-sub-boxes {
    padding: 26px 30px;
  }
}

.multisection-about-sub-text {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0% 20%;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 22px;
}


@media (max-width: 768px) {
  .multisection-about-sub-text {
    padding-left: 20px;
    padding-right: 20px;
  }

  .multisection-about-text {
    padding-left: 5px;
    padding-right: 5px;
  }

  .success-light-theme:nth-child(even) {
    opacity: 0.8;
    background: linear-gradient(180deg, rgba(178, 149, 121, 0.00) 0%, #593F26 105.86%);
    /* height: 150px; */
  }

  .success-light-theme:nth-child(odd) {
    opacity: 0.8;
    background: linear-gradient(180deg, #FFF 0%, rgba(178, 149, 121, 0.00) 64.86%);
    height: 150px;
  }

  .success-heading {
    top: 0%;
    text-align: center;
  }
}

.multisection-about-text-box {
  margin: 3%;
}

/* business section */
.business-section-box {
  background: #FFFBF6;
  height: 686px;
  padding-top: 5%;
}

@media screen and (max-device-width: 767px) {
  .business-section-box {

    padding-top: 10%;
  }
}
.business-section-small-img {
  background-image: url("./assets/images/home-b.png");
  width: 100%;
  height: auto;
  background-size: cover;
}

.business-section-large-img {
  background-image: url("../src/assets/images/Rectangle\ 126.png");
  height: 545.104px;
  background-repeat: no-repeat;
  background-size: cover;
}

.business-text {
  color: #000;
  font-family: Montserrat;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.9px;
  text-transform: uppercase;
  text-align: left;
}

.business-sub-text {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.business-text-list {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
}

.business-text-2 {
  color: #000;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.business-btn {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  background: #fff;
  border: 1px solid #000000;
}

/* categories css */
.categories-section {
  background-image: url("../src/assets/images/Rectangle\ 125.png");
  background-size: cover;
  height: 500px;
}

.categories-list {
  display: inline-flex;
  list-style: none;
}

ul.categories-list li {
  border-right: 1px solid #fff;
}

.categories-section-p {
  padding: 150px;
}

.categories-heading {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 30px;
  /* padding-left: 168px; */
}


.categories-list-heading {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  text-transform: uppercase;
}

@media screen and (max-device-width: 767px) {
  .categories-heading {
padding-top: 20px;
padding-bottom:20px ;
    padding-left: 0px;
  }
  .categories-list-heading {
    padding-top: 18px;
    padding-bottom: 20px;
  }   
  
}

.categories-text {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding: 10px;
}


.futuregoal-container {
  padding: 20px 150px;
}

.futuregoal-heading {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.futuregoal-subheading {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.futuregoals-list-conatiner {
  list-style: none;
  display: inline-flex;
  height: 190px;
}

/* .futuregoals-list-conatiner li:nth-child(odd) {} */

.futuregoals-list-conatiner li:nth-child(even) {
  position: relative;
  top: 50px;
}

.futuregoals-list-text {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  text-transform: uppercase;
  position: relative;
  top: -22px;
}

.futuregoals-list-count {
  text-align: center;
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  opacity: 0.6;
  background: linear-gradient(180deg, #BEA285 -18.42%, rgba(0, 0, 0, 0.00) 115.69%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  height: 52px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.futuregoal-heading-text {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.futuregoals-line-img {
  background-image: url("../src/assets/images/Group648.png");
  height: 140px;
  background-size: cover;
  background-position: center center;
}
@media screen and (max-device-width: 767px) {
  .futuregoals-line-img {
    height: 70px;
  }

  
.categories-text {
  padding-bottom: 20px ;
}

}
.img-conatiner {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-sub-conatiner {
  width: 250px;
  height: 250px;
  background: #fff;
  padding: 20px;
  margin-top: -58px;
  border-radius: 50%;
  top: -52px;
  position: relative;
}

/* homepage css */
.homepage-screen {
  background-image: url("../src/assets/images/homeimg.png");
  height: 540.579px;
  background-size: cover;
  background-position: center center;
}

.homepage-content-text-services {
  color: #FFF;
  /* font-size: 40px; */
  font-style: normal;
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-top: 60px !important;
}

.homepage-content-container {
  position: relative;
  top: 30%;
}

.homepage-schdule-btn {
  background: #fff !important;
  padding: 8px;
  color: #000 !important;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  border: none;
}

.success-stroy {
  padding-left: 50px;
}

@media (max-width: 768px) {
  .success-stroy {
    padding-left: 0px;
  }
}

@media screen and (max-device-width: 767px) {
  ul.categories-list.mt-4 {
    padding-left: 0px;
  }
}

.schedule-unlock-normal:hover {
  background: #B79D82;
  text-decoration: none;
  /* Change to green (#00FF00) on hover */
  color: black;
}

.homepage-screen-sub-conatiner {
  background: linear-gradient(180deg, rgba(178, 149, 121, 0.00) 0%, #593F26 105.86%);
  width: 672px;
  height: 540px;
  float: right;
}

.about-us-text-services {
  position: absolute;
  top: 50%;
  left: 55%;
  color: #FFF;
  font-size: 40px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 4px;
}

/* responsive css*/
.schedule-unlock-normal {
  background-color: #fff;
  padding: 16px 20px;
  text-decoration: none;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.futuregoal-container.row {
  padding: 0px;
}

@media screen and (max-device-width: 767px) {
  .categories-list {
    display: block;
  }

  .business-section-large-img {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  ul.categories-list li {
    border-right: unset;
    border-bottom: 1px solid #fff;
  }

  .group-section {
    height: unset;
  }

  .homepage-content-container {
    padding-left: 73vw;
  }

  .success-container {
    height: unset;
  }

  .team-section-conatiner {
    padding: unset;
  }

  .success-blog-box {
    height: 200px;
  }

  .business-section-box {
    height: unset;
  }

  .categories-section {
    height: 1000px;
  }

  .categories-section-p {
    padding: 0px;
  }

  .futuregoals-list-text {
    font-size: 12px;
    line-height: 26px;
  }

  .futuregoals-list-count {
    font-size: 50px;
    line-height: 18px;
  }

  .futuregoal-container {
    padding: 20px;
  }

  .team-text-heading {
    font-size: 28px;
    margin-top: 10px !important;
  }
}

/* about us */

.about-background {
  background: #FFFBF6 !important;
  /* padding: 150px 0px; */
}

.text-aboutUs Button {
  background: #B79D82;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  border: 0px;
}

/* Add responsive styles for mobile screens */

.schedule-call-button1 {
  background-color: #fff;
  padding: 16px 20px;
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.schedule-call-button1:hover {
  background: #B79D82;
  text-decoration: none;
  color: black;
}

button.schdule-btn.btn.btn-primary {
  padding: 12px 13px 11px 14px;
}

.access-btn.text-center {
  float: left;
}

.access-button1 button {
  background: #a39584;
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.access-button1 {
  float: left;
}

.access-button1 button:hover {
  background: #fff;
  color: #000;
  border: 1px solid #000;
}

.btn-access {
  padding: 10px;
  font-size: 15px;
}

.team-meeet button {
  background: #a39584;
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.meet-team-btn {
  float: left;
}

.team-meeet button:hover {
  background: #fff;
  color: #000;
  border: 1px solid #000;
}

.meet-team-btn {
  padding: 10px;
  font-size: 15px;
}


p.video-text {
  font-size: 23px;
  margin-left: 20px !important;
}


.backiosnewicon{
  margin-right: 150px !important;
}

.forwardiosicon{
  margin-left: 150px !important;
}

 @media (max-width: 768px) {
  .backiosnewicon{
  
    margin-right: 0px !important;
  
  }
  
  .forwardiosicon{
 
    margin-left: 0px !important;
  
  }
} 