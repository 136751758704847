.appointlaurelll {
    font-size: 30px;
}

.starkksss {
    text-align: center;
    font-size: 22px;
}

.starrttbtnnn {
    text-align: center;
}

.bbbttnnnn {
    font-size: 18px !important;
    font-weight: bold;
    padding: 10px 40px 10px 40px !important;
    border: 1px solid white;
    background-color: #C3AA91;
    color: white;
}

.bbbttnnnn:hover {
    color: black;
}

label.form-label.form-label {
    font-size: 24px;
}

.yesbuttonnnn {
    padding: 4px 60px 4px 60px !important;
    border-radius: 2px !important;
    font-size: 20px !important;
    font-weight: 600;
}

.yesbuttonnnn:hover {
    background-color: #C3AA91;
    color: white;
    border: 1px solid white;
}

.btnnnnokkk {
    background-color: #C3AA91;
    color: white;
    border: 1px solid white;
    font-size: 20px;
    font-weight: bold;
    padding: 7px 50px 7px 50px !important;
}

.btnnnnokkk:hover {
    background-color: black;
    color: white;
}

.subbmmittbttn {
    border: none;
    font-size: 19px;
    font-weight: bold;
    background-color: #C3AA91;
    color: white;
}

.subbmmittbttn:hover {
    background-color: black;
    color: white;
}
/* Add this to your CSS file */

.step4-options:focus,
.step4-options:hover,
.step4-options:active,
.selected {
  background-color: #C3AA91;
  color: #fff;
}

.selected-option {
  margin-top: 10px;
  color: #C3AA91;
}
