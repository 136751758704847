.team-purpose-text {
  top: 45%;
  font-size: 20px;
  line-height: 34px;
}

.schedule-button:hover {
  background: #B79D82;
  text-decoration: none;
  color: black;
}

.about-background {
  background: #FFFBF6 !important;
  padding: 50px 0px;
}

.text-aboutUs {
  padding-top: 60px;
}

.text-aboutUs p {
  color: #000;
  font-family: Montserrat;
  /* line-height: 27px; */
  text-align: left;
  color: #000;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  line-height: 31px;
}

.text-aboutUs Button {
  background: #B79D82;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  border: 0px;
}

.about-us-p1 {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.left-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}

.left-button {
  background: #B79D82;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  border: 0;
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .left-button-container {
    justify-content: center;
  }
}

.text-aboutUs h2 {
  text-align: left;
  color: #000;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.6px;
  text-transform: uppercase;
}

.joe-divorce {
  font-size: 18px;
  padding-left: 100px;
  color: white;
}

.valuations .fair {
  margin-bottom: 10px;
}


.about-us h4 {
  padding: 150px;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.about-us p {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: -111px;
}

.valuations {
  text-decoration: none;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 135px;
  list-style: none;
  background: transparent !important;
}

@media (max-width: 768px) {
  .joe-divorce {
    font-size: 20px;
    padding-left: 100px;
    padding: 12px;
    color: white;
  }
}


.about-us1 {
  background-image: url("../../assets/images/Subtract.png");
  height: 400px;
  background-size: cover;
  position: relative;
}

.about-us-text {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.team-purpose-text {
  position: absolute;
  text-align: center;
  width: 100%;
  color: white;
  font-size: 25px;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.schedule-button {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 16px 20px;
  text-decoration: none;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.schedule-button:hover {
  background: #B79D82;
  text-decoration: none;
  color: black;
}

@media (max-width: 768px) {
  .about-us1 {
    background-image: url("../../assets/images/Subtract.png");
    background-size: cover;
    position: relative;
  }

  .about-us-text {
    font-size: 24px;
  }

  .team-purpose-text {
    font-size: 14px;
    padding: 5px;
  }

  .schedule-button {
    top: 65%;
    padding: 10px 15px;
    font-size: 14px;
  }
}

/* */

.about-us-fair {
  background-image: url("../../assets/images/Rectangle 125.png");
  background-size: cover;
  width: 100%;
  padding: 52px 21px;
}

h4.text-center.expert.mt-5 {
  padding-top: 23px;
  padding-left: 100px;
}

.expert {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.valuations .fair {
  margin-bottom: 10px !important;
}

.valuations {
  text-decoration: none;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  list-style: none;
  background: transparent !important;
}

.fair {
  padding: 18px 31px;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #fff;
  color: #f1f1f1;
  border-radius: 0;
  transition: 0.2s;
  font-size: 12px;
}

@media (max-width: 768px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  h4.text-center.expert.mt-5 {
    padding-top: 23px;
    padding-left: 0px;
  }

  .fair {
    padding: 12px 12px;

  }
}

.mediator p {
  color: #000 !important;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
}

.mediator p span {
  color: #000;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

@media (max-width: 768px) {
  .mediator p {
    font-size: 18px;
    line-height: 20px;
    padding: 0 25px;
  }

  .mediator p span {
    font-size: 20px;
    line-height: 22px;
  }
}

/* */
.centered-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.centered-button {
  background: #B79D82;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  border: 0;
  padding: 15px 22px;
}

.fair-maket-list {
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 768px) {
  .fair-maket-list {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 768px) {
  .about-background {
    padding: 20px;
  }


}


.coordinator {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.coordinator h3 {
  text-align: left;
}

.coordinator p {
  text-align: left;
  font-size: 18px;
}


.marketing {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.marketing h3 {
  text-align: left;
}

.marketing p {
  text-align: left;
  font-size: 18px;
}


.lesley {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.lesley h3 {
  text-align: left;
}

.lesley p {
  text-align: left;
  font-size: 18px;
}

@media (max-width: 768px) {
  .coordinator {
    padding: 0px !important;
  }
  .marketing {
    padding: 0px !important;
  }
  .lesley {
    padding: 0px !important;
  }

}