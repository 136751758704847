.appraisalproperty {
    font-size: 33px;
    /* color:white; */
}

.obtainingappraisal {
    text-align: center;
    font-size: 22px;
    /* color: white; */
}

.getstartedddbtnnn {
    text-align: center !important;
}

.get-started {
    float: none !important;
}

.getttttbtnnn {
    border: 1px solid white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 31px 10px 30px;
    background-color: #C3AA91;
    color: white;
}

.getttttbtnnn:hover {
    color: black;
}

.okkkkbtnnn {
    border: 1px solid white;
    font-size: 20px;
    font-weight: bold;
    padding: 7px 50px 7px 50px !important;
    background-color: #C3AA91;
    color: white;
}

.okkkkbtnnn:hover {
    background-color: black;
    color: white;
}

.phoneeeinputtt {
    width: 45%;
    padding: 10px 10px 10px 10px;
}

input.PhoneInputInput {
    padding: 10px 0px 10px 16px;
    font-size: 14px;
    font-weight: 500;
}

.yessbtnnnn {
    border: 1px solid white;
    font-size: 20px;
    padding: 4px 60px 4px 60px;
    font-weight: 600;
}

.nooobtnnnn {
    border: 1px solid white;
    font-size: 20px;
    padding: 4px 60px 4px 60px;
    font-weight: 600;
}

.yessbtnnnn:hover {
    border: 1px solid white;
    background-color: #C3AA91;
    color: white;
}

.nooobtnnnn:hover {
    border: 1px solid white;
    background-color: #C3AA91;
    color: white;
}

.clicckkimggg {
    border: 2px solid;
    height: 145px;
    width: 275px;
    font-size: 27px;
    padding: 18px 12px 12px 16px;
}

.monthhhh {
    width: 150px !important;
}

.btnnnvaluee {
    border: 1px solid;
    font-size: 18px;
    padding: 10px 30px 10px 30px;
    border: 2px solid black !important;
}

.btnnnvaluee:hover {
    background-color: #C3AA91;
    color: white;
}

.submittbuttonnn {
    border: none;
    font-size: 19px;
    font-weight: bold;
    background-color: #C3AA91;
    color: white;
}

.submittbuttonnn:hover {
    background-color: black;
    color: white;
}

.header-form-inputs {
    margin-left: 0px !important;
}

.imaaggeeuploadd {
    height: auto;
    min-width: 100%;
}

.labeelconteent {
    font-size: 18px !important;
}

.step-11-label {
    font-size: 12px !important;
    margin-left: 6px !important;
}

.step-11-arrow {
    font-size: 13px;
    margin-bottom: 4px !important;
}

.step-11-span {
    font-size: 14px;
}

p.error-message {
    color: red !important;
    font-size: 13px;
}

img.uploaded-image {
    width: inherit;
    height: min-content;
}

.react-datepicker-popper {
    width: 50% !important;
    margin-top: 3% !important;
}

button.react-datepicker__close-icon {
    display: none;
}

@media (max-width: 768px) {
    .btnnnvaluee {
        padding: 10px 3px 10px 8px !important;
    }

    img.uploaded-image {
        width: 40% !important;
        height: auto;
    }

}

