.pageeserrorss{
    font-size: 12.5rem;
    margin-top: 88px !important;
}
.nottfounddd{
    font-size: 3srem;
    margin-top: 0.5rem!important;
}
.lookingpageee{
    margin-top: 15px !important;
    font-size: 15px;
}
.homepageeeerrorr{
    font-size: 15px;
    margin-bottom: 60px !important;
}