.footer-links h5 {
  font-size: 18px;
  line-height: 26px;
  text-align: left;
}
.footer-links,
.footer-links-address {
  font-family: Montserrat;
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
}

.footer-links-address p {
  font-size: 18px;
}

.footer-links li {
  list-style-type: none;
  font-weight: 300;
  font-size: 18px;
}

.dark-footer {
  background: #c4ac93;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  padding: 20px;
}

.dark-footer p {
  text-align: center;
}

/* Media query for mobile screens */
@media (max-width: 767px) {
  .footer-links h5 {

    text-align: center;
  }

  .footer-links li {
    text-align: center;

  }
}

.no-decoration {
  text-decoration: none;
}

.footer-links li a {
  text-decoration: none;
  color: black;
}