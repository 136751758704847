
.modal-content {
    /* background-image: url(../../assets/images/Rectangle\ 129.png); */
    background-image: url(../../assets/images/model\ 1.jpg);
    width: 100%;
    background-size: cover;
   
}

.modaalll{
 font-size: 33px;
 /* color:white; */
}
.ownershipp{
    text-align: center;
    font-size: 22px;
    /* color: white; */
}
.btnnnnn{
    padding: 11px 26px 11px 26px;
float: none !important;
border: none;
background-color: #C3AA91;
color: white;
box-shadow: 12px 19px 25px 0px rgba(0,0,0,0.1);
font-weight: bold;
border: 1px solid white;
}
.gettstarteddd{
    text-align: center !important;  
}
.btnnnnn:hover{
    color: black;
}
label.from-label.form-label {
    font-size: 20px !important;
}
.okbuttonnn{
    background-color: #C3AA91;
color: white;
    border: 1px solid white;
    padding: 12px 60px 12px 61px !important;
    font-size: 16px;
    font-weight: bold;   
}
.grantbtnnnn{
    width: 100% !important;
    padding: 13px 0px 13px 20px;
    font-size: 17px;
    font-weight: bold;
}
.submittbtnnnn{
    background-color: #C3AA91;
    color: white;
    border: none;
    font-size: 19px;
    font-weight: bold;
}
.okbuttonnn:hover{
background-color: black;
color: white;
}
.grantbtnnnn:hover{
    background-color: #C3AA91;
    color: white; 
}
.submittbtnnnn:hover{
    background-color: black;
    color: white; 
}
.fontttdesignn{
    font-size: 27px;
    font-weight: 600;
}
.arrowdesignnnn{
    font-size: 17px;
    margin-bottom: 10px !important;
}
label.form-label {
    margin-left: 12px !important;
}
.enternameee{
    margin-left: 0px !important;
}
.validaattiioon{
    color: #000;
    font-size: 14px;
}
.realpropertyyydoc{
    font-size: 33px !important;
}
.ownershippp{
    font-size: 22px !important;
    text-align: center !important;
}
.startedgetbtnn{
text-align: center;
}
.buttonstartget{
    border: 1px solid white !important;
    font-size: 16px !important;
    font-weight: bold !important;
    padding: 12px 27px 12px 27px !important ;
    background-color: #C3AA91 !important;
    color: white !important;
}
.buttonstartget:hover{
    color: black !important;
    font-weight: bolder !important;
}
.bttnnookkk{
    border: 1px solid white !important;
    font-size: 20px !important;
    font-weight: bold !important;
    padding: 12px 50px 12px 50px !important;
    background-color: #C3AA91 !important;
    color: white !important;
}
.bttnnookkk:hover{
    background-color: black !important;
    color: white !important;
}
.buttonsubbmitt{
    border: none !important ;
    font-size: 19px !important;
    font-weight: bold !important;
    background-color: #C3AA91 !important;
    color: white !important;
    padding: 9px 30px 9px 30px !important       ;
    
}
.buttonsubbmitt:hover{
    background-color: black !important;
    color: white !important;
}
.deadddbttnn{
    padding: 15px 0px 15px 0px !important;
}
.deadddbttnn:hover{
    background-color: #C3AA91 !important;
    color: white !important;
    font-weight: bolder !important;
}
.invalid-feedback {
    font-size: 13px;
}
@media (max-width: 768px) {
    .modal-content {
        background-image: url(../../assets/images/Rectangle\ 129.png);
        width: 100%;
        height: auto !important;
        background-size: cover;     
    }
    input.header-form-inputs.form-control {
      width: 100% !important;
      margin-left: 0px !important;    
    }
    .modal-dialog.modal-lg.modal-dialog-centered {
        margin-left: 21px !important;
    }
    .ownershipp{
        font-size: 14px; 
    }
    
    .grantbtnnnn{
        font-size: 12px !important;
    }
    .fontttdesignn{
        font-size: 19px;
    }
    .arrowdesignnnn{
        font-size: 13px;
        margin-bottom: 8px !important;
    }
}

